import React from "react"
import PropTypes from 'prop-types'
import { graphql, Link } from "gatsby"
import Img from 'gatsby-image'
// ---------------------------------------------
import ExDisplayPrices from "../components/exDisplayPrices"
import Enquire from "../components/exDisplayEnquire"
import Layout from "../components/layout"
import Revealer from "../components/revealer"
import SEO from "../components/seo"
import Markdown from "../components/markdown"
// ---------------------------------------------

const ExDisplayIndex = ({ data }) => {

  const {
		title,
		checkBackAgainSoon,
		introduction,
		products
	} = data.allContentfulExDisplayPage.nodes[0]

	const availableProducts = products.filter(p => !p.soldOut)
	const hasAvailableProducts = availableProducts.length > 0
	const pluralisedPiece = availableProducts.length > 1 ? 'pieces' : 'piece'
	const subheading = hasAvailableProducts ? `${availableProducts.length} ${pluralisedPiece} available` : checkBackAgainSoon

  return (
    <Layout footerColour="#FFFFFF" hasNewsletter>
      <SEO title="Ex-Display" />

      <Revealer>
        <div className="innerx4" style={{
          backgroundColor: '#E4DDD5'
        }}>

          <section className="innerx4 align-center">
            <h1 className="upcase">{title}</h1>
            <h1>{subheading}</h1>
          </section>

          <section className="no-bullets">
						<ul className="journal-index pad">
							<li className="span-4 inner gutters p2 no-bullets">
								<Markdown field={introduction} className="md outer" />
							</li>
							{products.map((product, i) => {
								const {
									productName,
									slug,
									soldOut,
									createdAtSlug,
									materialcolour,
									originalPrice,
									discountPrice,
									thumbnail
								} = product

								// determine if image is landscape
								// if > 1.2 = landscape

								console.log(thumbnail)

								return (
									<li className={`span-4 inner gutters p1 no-bullets ${soldOut ? 'sold-out' : ''}`} key={`${slug}-${i}`}>
										<Revealer className="fs">
											<figure>
												<div className="outer">
													<p>
														<strong>{productName}{" "}</strong> in {materialcolour}
													</p>
													{!soldOut ? (
														<ExDisplayPrices 
															originalPrice={originalPrice} 
															discountPrice={discountPrice} 
														/>
													) : (
														<p>Sold</p>
													)}
													
												</div>

												<div className="w100">
													<Img fluid={{ ...thumbnail.fluid, aspectRatio: 0.77 }} />
												</div>

												{!soldOut && (
													<div className="pabs b0 w100 align-center guttersx4 innerx4">
														<div className="gutters inlb">
															<Enquire className="btn white mb" title={productName} materialcolour={materialcolour} />
														</div>
														<div className="inlb gutters">
															<Link to={`/ex-display/${slug}-${createdAtSlug}`} className="btn white">More info</Link>
														</div>
													</div>
												)}
											</figure>
										</Revealer>
									</li>
								)
							})}
						</ul>
          </section>
        </div>
      </Revealer>

    </Layout>
  )
}

ExDisplayIndex.propTypes = {
  data: PropTypes.object.isRequired
}

export default ExDisplayIndex

export const pageQuery = graphql`

	fragment ExDisplayLink on ContentfulExDisplay {
		title
		productName
		slug
		createdAtSlug: createdAt(formatString: "DD-MM-YYYY")
		materialcolour
		soldOut
		originalPrice
		discountPrice
		thumbnail {
			fluid(maxWidth: 1600) {
				...GatsbyContentfulFluid_withWebp_noBase64
			}
		}
	}

  query ExDisplayIndexQuery {
    allContentfulExDisplayPage(limit: 1) {
			nodes {
				title
				checkBackAgainSoon
				introduction {
					childMarkdownRemark {
						html
					}
				}
				products {
					...ExDisplayLink
				}
			}
		}
  }
`
